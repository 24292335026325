import React, { Component } from 'react';
import ReactGA from 'react-ga';
import axios from 'axios';

import SpeechToText from 'speech-to-text';
import {
  GridList,
  GridListTile,
  GridListTileBar,
  Typography,
  IconButton,
  AppBar,
  Card,
  CardMedia,
  Snackbar,
  InputBase,
  Toolbar,
  Dialog,
  Fab,
  Slide,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CssBaseline
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Info, Search, Mic, MicOff } from '@material-ui/icons';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  content: {
    marginTop: theme.spacing.unit * 8
  },
  grow: {
    flexGrow: 1
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: theme.spacing.unit * 65
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 7,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 7,
    transition: theme.transitions.create('width'),
    width: '100%'
  },
  link: {
    color: 'inherit'
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  },
  infoText: {
    paddingTop: theme.spacing.unit * 11,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit
  },
  media: {
    height: 0,
    paddingTop: '61.8%' // golden ratio
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  }
});

const Transition = props => <Slide direction="up" {...props} />;

class ImageGridList extends Component {
  state = {
    images: [],
    showAppInfo: false,
    search: '',
    interimText: '',
    snackBarMessage: '',
    currentImageURL: '',
    showFullImage: false,
    currentPage: 1,
    listening: false
  };

  fetchImagesFromUnsplash = async text => {
    try {
      const imagesPerQuery = 30; // this seems to the max unsplash will return to me
      const response = await axios(
        `https://api.unsplash.com/search/photos?page=${this.state.currentPage}&per_page=${imagesPerQuery}&query=${text}&client_id=${process.env.REACT_APP_SPLASH_ACCESS_KEY}`
      );

      return response.data;
    } catch (error) {
      this.setState({
        error: `Sorry, error: ${error.message}`,
        interimText: '',
        search: '',
        images: []
      });
    }
  };

  notifyOfDownload = async downloadLocation => {
    try {
      await axios(
        `${downloadLocation}?client_id=${process.env.REACT_APP_SPLASH_ACCESS_KEY}`
      );
    } catch (error) {
      this.setState({
        error: `Sorry, error: ${error.message}`
      });
    }
  };

  /*
    A few scenarios:

    1) the speech engine disconnect after a time, so we auto re-start it if we have the state to listening
    2) we manually stop listening, so we do not want the listener to start again
    3) on mobile if it stop listening, we don't wan to restart listening, and need to set listening to false
  */
  onEndEvent = () => {
    if (!isWidthUp('sm', this.props.width)) {
      this.setState({ listening: false });
    } else if (this.state.listening) {
      this.speechToText.startListening();
    }
  };
  onFinalised = async text => {
    const data = await this.fetchImagesFromUnsplash(text);

    if (data) {
      this.setState({
        images: data.results,
        totalImages: data.total,
        totalPages: data.total_pages,
        search: text,
        interimText: '',
        snackBarMessage: data.total === 0 ? `No images found for "${text}"` : ''
      });
    }

    // smoothly scroll back to the top whenever a new search is made
    window.scrollTo(0, 0);
  };

  onInterimText = interimText => {
    this.setState({
      interimText,
      search: '',
      showAppInfo: false
    });
  };

  toggleListening = () => {
    this.setState({
      listening: !this.state.listening,
      snackBarMessage: this.state.listening
        ? 'Listening stopped.'
        : "I'm listening!"
    });
    if (this.state.listening) {
      this.speechToText.stopListening();
    } else {
      this.speechToText.startListening();
    }
  };

  componentDidMount = () => {
    try {
      // setup Google Analytics
      ReactGA.initialize('UA-63340534-6');
      ReactGA.pageview(window.location.pathname + window.location.search);

      // setup the speech recognition
      this.speechToText = new SpeechToText(
        this.onFinalised,
        this.onEndEvent,
        this.onInterimText
      );
    } catch (error) {
      this.setState({
        error: error.message
      });
    }
  };

  render() {
    const {
      images,
      search,
      showAppInfo,
      interimText,
      snackBarMessage,
      showFullImage,
      currentImageURL,
      listening,
      error
    } = this.state;
    const { classes } = this.props;
    const utmParameters = '?utm_source=syzygy_solutions&utm_medium=referral';

    let content;
    if (images.length === 0) {
      let mainMessage = '';

      if (error) {
        mainMessage = error;
      } else if (listening) {
        mainMessage = "Speak when you're ready...";
      } else {
        mainMessage = 'Tap the mic button to begin.';
      }
      content = (
        <Typography align="center" className={classes.infoText} variant="h5">
          {mainMessage}
        </Typography>
      );
    } else {
      const numColumns = isWidthUp('sm', this.props.width) ? 2 : 1;

      content = (
        <div className={classes.root}>
          <GridList cellHeight={440} cols={numColumns}>
            {images.map(image => (
              <GridListTile key={image.id}>
                <img
                  src={image.urls.regular}
                  alt={image.description}
                  onClick={() => {
                    if (numColumns === 2) {
                      this.setState({
                        showFullImage: true,
                        currentImageURL: image.urls.regular
                      });
                    }
                  }}
                />
                <GridListTileBar
                  subtitle={
                    <span>
                      Photo by{' '}
                      <a
                        href={`${image.user.links.html}${utmParameters}`}
                        className={classes.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {image.user.name}
                      </a>{' '}
                      (
                      <a
                        href={`${image.links.html}${utmParameters}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={`Download ${image.description}`}
                        className={classes.link}
                        onClick={() =>
                          this.notifyOfDownload(image.links.download_location)
                        }
                      >
                        download
                      </a>
                      )
                    </span>
                  }
                />
              </GridListTile>
            ))}
          </GridList>
        </div>
      );
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" color="inherit">
              Syzygy Solutions
            </Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <Search />
              </div>
              <InputBase
                placeholder="Search…"
                value={interimText || search}
                readOnly
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
              />
            </div>
            <div className={classes.grow} />
            <IconButton
              color="inherit"
              onClick={() => this.setState({ showAppInfo: true })}
            >
              <Info />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Dialog
          open={showAppInfo}
          TransitionComponent={Transition}
          onClose={() => this.setState({ showAppInfo: false })}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {'Syzygy Solutions'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description" gutterBottom>
              All photos can be used for{' '}
              <a href="https://unsplash.com/license">free</a>!
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description" gutterBottom>
              This app was developed by Andrew Golightly. Any questions email
              him at{' '}
              <a href="mailto:support@andrewgolightly.com">
                support@andrewgolightly.com
              </a>
            </DialogContentText>
            <DialogContentText variant="caption">
              All images are sourced from{' '}
              <a href={`https://unsplash.com${utmParameters}`}>Unsplash</a>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <div className={classes.content}>{content}</div>
        <Fab
          color="secondary"
          aria-label="Mic"
          className={classes.fab}
          onClick={() => this.toggleListening()}
        >
          {listening ? <MicOff /> : <Mic />}
        </Fab>
        <Dialog
          fullWidth
          maxWidth={'xl'}
          open={showFullImage}
          onClose={() => this.setState({ showFullImage: false })}
          TransitionComponent={Transition}
        >
          <Card>
            <CardMedia className={classes.media} image={currentImageURL} />
          </Card>
        </Dialog>
        <Snackbar
          open={snackBarMessage !== ''}
          autoHideDuration={3300}
          onClose={() => this.setState({ snackBarMessage: '', search: '' })}
          message={<span>{snackBarMessage}</span>}
        />
      </React.Fragment>
    );
  }
}

export default withWidth()(withStyles(styles)(ImageGridList));
